
import Plane from "./../pic/plane.svg"
import Truck from "./../pic/truck.svg"
import Boat from "./../pic/boat.svg"
import importin from "./../pic/import_export.svg"
import file from "./../pic/file.svg";
export const Service = [
    {
        key:1,
        pic:Plane,
        title:"Fret aérien",
        des:"Des solutions de transport aérien rapides et fiables.",
    },
    {
        key:2,
        pic:Boat,
        title:"Fret maritime",
        des:"Services de fret maritime rentables et étendus.",
    },
    {
        key:3,
        pic:Truck,
        title:"Fret routier",
        des:"Transport terrestre efficace et rapide."
    },
    {
        key:4,
        pic:importin,
        title:"Facilitez l’Export / Import",
        des:"Gérez vos échanges internationaux sans stress : logistique, dédouanement, livraison rapide."

    },
    {
        key:5,
        pic:file,
        title:"Gestion des Douanes et Accords",
        des:"Simplifiez vos démarches douanières : formalités, réglementations, et accords gérés pour un transit sans souci."
    }
]