import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Nav from './component/Nav';
import Formulair from './page/Formulair'; // Import the Formulair component
import Home from './page/Home';
import NewFooter from './component/NewFooter';
import SubFooter from './page/SubFooter';

function Main() {
  const location = useLocation();

  return (
    <>
      {/* Render Nav only if the current route is not "/devis" */}
      {location.pathname !== '/devis' && <Nav />}

      {/* Routes definition */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/devis" element={<Formulair />} />
      </Routes>

      {/* Footer displayed on all routes */}
      <NewFooter />
      <SubFooter/>
    </>
  );
}

export default Main;
