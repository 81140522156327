import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

const PourquoiNous = () => {
  const galleryData = [
    'Nous offrons la meilleure qualité à des prix compétitifs.',
    'Notre équipe assure une livraison et une logistique sans faille.',
    'Des solutions différentes pour des besoins de transport différents',
    'La satisfaction du client est notre priorité absolue.',
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  // Automatically swipe descriptions
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryData.length);
    }, 3000); // Change description every 3 seconds

    return () => clearInterval(interval);
  }, [galleryData.length]);

  // Using Intersection Observer for animations
  const { ref: headerRef, inView: headerInView } = useInView({ triggerOnce: true });
  const { ref: descriptionRef, inView: descriptionInView } = useInView({ triggerOnce: true });

  return (
    <div id="whyus" className="Pourquoi_nous">
      <h1 ref={headerRef} className={headerInView ? 'fade-in' : ''}>
        Pourquoi Nous
      </h1>
      <div className="Line"></div>
      <div
        ref={descriptionRef}
        className={`description ${descriptionInView ? 'fade-in' : ''}`}
      >
        <p>{galleryData[currentIndex]}</p>
      </div>
    </div>
  );
};

export default PourquoiNous;
