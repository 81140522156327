import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import Swipper from "../Swipper";

function Abtus() {
    const { ref, inView } = useInView({ threshold: 0.1 , triggerOnce: true  });

    const textVariant = {
        hidden: { scale: 0.8, opacity: 0 },
        visible: { scale: 1, opacity: 1, transition: { duration: 1, ease: "easeOut" } },
        exit: { scale: 1.5, opacity: 0, transition: { duration: 0.8 } },
    };

    const paragraphVariant = {
        hidden: { y: 50, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: "easeOut", delay: 0.4 },
        },
        exit: { y: -50, opacity: 0, transition: { duration: 0.8 } },
    };

    const swiperVariant = {
        hidden: { y: 100, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: 1, ease: "easeOut", delay: 0.8 },
        },
    };

    return (
        <div id="Aboutus" className="Abtus" ref={ref}>
            <img/>
            <motion.h3
                variants={textVariant}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                exit="exit"
            >
                Qui nous sommes ?
            </motion.h3>
            <motion.div className="Line">

            </motion.div>
            <motion.p
                variants={paragraphVariant}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                exit="exit"
            >
                Nous sommes spécialistes de la logistique et du transport de marchandises, garantissant
                efficacité et fiabilité du début à la fin. Notre expertise dans le domaine nous permet de
                vous proposer des solutions sur mesure pour répondre à tous vos besoins de transport.
                <br />
                Avec un réseau mondial et des années d'expérience, nous nous engageons à fournir des
                services de premier ordre auxquels vous pouvez faire confiance. Notre mission est de
                faciliter des solutions transparentes de logistique et de fret qui aident votre entreprise
                à prospérer.
            </motion.p>
            <motion.div
                className="SwipperContainer"
                variants={swiperVariant}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
            >
                <div className="SWP">
                    <Swipper />
                </div>
            </motion.div>
        </div>
    );
}

export default Abtus;
