import React from 'react';

function SubFooter() {
    const currentYear = new Date().getFullYear(); // Get the current year dynamically

    // Inline styles
    const footerStyles = {
        textAlign: 'center',
        padding: '1rem',
        color: '#333',
        fontSize: '10px',
        borderTop: '1px solid #ddd',
        position: 'relative',
        bottom: 0,
        
    };

    const textStyles = {
        margin: 0,
        color: '#115549', // Moroccan green color
    };

    return (
        <div id="subfooter" className="sub_footer" style={footerStyles}>
            <p style={textStyles}>© {currentYear} ZL Cargo Maroc. All rights reserved.</p>
        </div>
    );
}

export default SubFooter;
