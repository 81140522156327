import React from 'react'
import Logo from "./../../pic/lg3.png"
import Tel from "./../../pic/tel.svg"
import Map from "./../../pic/map.svg"
import { Link } from 'react-router-dom'
import mail from "../../pic/mail_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24 (1).svg"
function Navmin() {
    return (
        <div className='navmin_cont'>
            <div className='info_contact'>
                <div><a href='tel:0669461672'><img  src={Tel} alt="tel"/></a>(+212) 669-461672</div>
                <div><a href='https://maps.app.goo.gl/szR3XTySdAm89LTdA'><img src={Map} alt="map"/></a></div>
            </div>
            <div className='navmin'>
                <div className='logo'>
                    {/* <span>Zl Cargo Maroc</span> */}
                    <img src={Logo} alt="ZL Gargo Maroc" />
                </div>
                <div className='links'>
                    <a href='#Whoarewe'>Home</a>
                    <a href='#Aboutus'>Nous Sommes</a>
                    <a href='#ourservices'>Nos Services</a>
                    <a href='#whyus'>Pourquoi nous</a>
                    <a href='#contact'>Contactez-nous</a>
                    <Link className='Devis_demand' to="devis"><img src={mail} alt="mail"/>Devis</Link>
                </div>
            </div>
        </div>
    )
}

export default Navmin
