import React from 'react';
import { useInView } from 'react-intersection-observer';
import world from "../../pic/world.png";
import { Service } from '../../db/services';

function Services() {
    const { ref: headerRef, inView: headerInView } = useInView({ triggerOnce: true });
    const { ref: titleRef, inView: titleInView } = useInView({ triggerOnce: true });
    const { ref: cardsRef, inView: cardsInView } = useInView({ triggerOnce: true });

    return (
        <div id='ourservices' className='Services'>
            <img className='world' src={world} alt="world" />
            <div className='serv_content'>
                {/* Header animation */}
                <h1
                    ref={headerRef}
                    className={`ns_header ${headerInView ? 'animate-from-left' : ''}`}
                >
                    Nos Services
                </h1>
                <div className='Line'></div>
                
                {/* Title animation */}
                <h3
                    ref={titleRef}
                    className={`title ${titleInView ? 'animate-from-left' : ''}`}
                >
                    CE QUE NOUS POUVONS VOUS OFFRIR
                </h3>
                
                <div className='Our_srv_cont'>
                    {/* Service cards animation */}
                    <div
                        ref={cardsRef}
                        className={`our_srv ${cardsInView ? 'cards-visible' : ''}`}
                    >
                        {Service.map((val) => (
                            <div className='srv_cart' key={val.key}>
                                <div className='inline'>
                                    <img src={val.pic} alt="pic" />
                                    <h3>{val.title}</h3>
                                    <div></div>
                                </div>
                                <p>{val.des}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Services;
