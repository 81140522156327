import one from "./../pic/zl_img/zlcargomaroc.webp"
import two from "./../pic/zl_img/morocco_china.webp"
import three from "./../pic/zl_img/fruits.webp"
import all from "./../pic/zl_img/medical.webp"
const arrswp = [
    {
        key:1,
        src:one
    },
    {
        key:2,
        src:two
    },
    {
        key:3,
        src:three
    },
    {
        key:4,
        src:all
    }
]

export default arrswp
