import React from 'react';
import LOGO from "../pic/lg3.png";
import LOCAL from "../pic/map.svg"
import Phone from "../pic/tel.svg"
import Internet from "../pic/language_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
import Email from "../pic/mail_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg"
import plane from "../pic/plane.svg"
import boat from "../pic/boat.svg"
import truck from "../pic/truck.svg"
import file from "../pic/file.svg"
import facbook from "../pic/facebook.svg"
import instagram from "../pic/instagram.svg"
import linkdin from "../pic/linkedin.svg"
function NewFooter() {
    return (
        <footer >
            <div className="footer__container">
                {/* Company Info Section */}
                <div className="footer__logo">
                    <img src={LOGO} alt="Logo" />
                    <p>
                        ZL CARGO MAROC société SARL est spécialisée dans l'import-export,
                        fournissant des services professionnels et reconnus.
                    </p>
                    <div className="footer__socials">
                        <a href="https://www.facebook.com/profile.php?id=61570305710036" target="_blank" rel="noopener noreferrer">
                            <img className='' src={facbook} alt="r_s" />
                        </a>
                        <a href="https://www.instagram.com/zl_cargo_maroc/" target="_blank" rel="noopener noreferrer">
                            <img className='' src={instagram} alt="r_s" />
                        </a>
                        <a href="https://www.linkedin.com/company/zlcargomaroc/" target="_blank" rel="noopener noreferrer">
                            <img className='' src={linkdin} alt="r_s" />
                        </a>
                    </div>
                </div>

                {/* Contact Information Section */}
                <div className="footer__contact">
                    <h3>INFORMATIONS DE CONTACT</h3>
                    <ul>
                        <li><img src={LOCAL} alt="INFORMATIONS" /> 357 Bd Med V, 3éme étage N°5,Espace A6 Casablanca – Maroc</li>
                        <li><img src={Internet} alt="INFORMATIONS" /><a href="https://www.zlcargomaroc.com" target="_blank" rel="noopener noreferrer"> www.zlcargomaroc.com</a></li>
                        <li><img src={Phone} alt="INFORMATIONS" /> +212-520-94-64-38</li>
                        <li><img src={Email} alt="INFORMATIONS" /> <a href="mailto:operation@zlcargomaroc.com"> operation@zlcargomaroc.com</a></li>
                        <li><img src={Email} alt="INFORMATIONS" /> <a href="mailto:sales@zlcargomaroc.com"> sales@zlcargomaroc.com</a></li>
                    </ul>
                </div>

                {/* Services Section */}
                <div className="footer__services">
                    <h3>SERVICES</h3>
                    <ul>
                        <li><img src={boat} alt="" />Transport Maritime</li>
                        <li><img src={plane} alt="" />Transport Aérien</li>
                        <li><img src={truck} alt="" />Transport Routier</li>
                        <li><img src={file} alt="" />Douane</li>
                        {/* <li><img src={mmm} alt=""/>Time Critical</li> */}
                    </ul>
                </div>
            </div>

            {/* Footer Bottom */}
            {/* <div className="footer__bottom">
                <p>&copy; 2025 JULIA TRANS SARL. Tous droits réservés.</p>
            </div> */}
        </footer>
    );
}

export default NewFooter;
