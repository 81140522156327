import ONE from "../pic/zl_img/zlcargomaroc2.webp"
import TWO from "../pic/zl_img/colis_zl.webp"
import THREE from "../pic/zl_img/morocco_china.webp"
import FOR from "../pic/zl_img/fruits.webp"
const moreservices = [
    {
        title: 'ALL Services ',
        description: "Transport international assuré : conteneurs, fret maritime et solutions logistiques sur mesure",
        image: ONE,
    },
    {
        title: 'Transport De Colis',
        description: 'Livraison à domicile en toute sécurité, adaptée à vos délais et exigences, où que vous soyez.',
        image: TWO,
    },
    {
        title: 'Relation Maroc-Chine',
        description: 'Spécialiste des importations et exportations entre Maroc et Chine, avec services fiables et rapides.',
        image: THREE,
    },
    {
        title: 'FRUITS & VEGETABLES',
        description: 'Import-export mondial de fruits et légumes frais, avec logistique optimisée.',
        image: FOR,
    },
];

export default moreservices;