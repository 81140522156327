import React, { useState, useRef } from 'react';
import services from '../../db/moreservices';


function Moreservices() {
    const [currentPage, setCurrentPage] = useState(0);
    const servicesRef = useRef(null);

    // Handle Scroll Event to update pagination dots
    const handleScroll = () => {
        if (servicesRef.current) {
            const scrollLeft = servicesRef.current.scrollLeft;
            const width = servicesRef.current.clientWidth;
            const index = Math.round(scrollLeft / width);
            setCurrentPage(index);
        }
    };

    return (
        <div className='www'>
            <div className='services-container'>
                <h3 className='services-header'>les solutions que nous proposons</h3>
                <div className='Line'></div>
                <div className='services-carousel' ref={servicesRef} onScroll={handleScroll}>
                    {services.map((service, index) => (
                        <div className='service-card' key={index}>
                            <img src={service.image} alt={service.title} />
                            <div className='service_content'>
                                <h2>{service.title}</h2>
                                <div className='underline'></div>
                                <p>{service.description}</p>
                            </div>
                        </div>
                    ))}
                </div>

                <div className='pagination-dots'>
                    {services.map((_, index) => (
                        <span
                            key={index}
                            className={`dot ${currentPage === index ? 'active' : ''}`}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Moreservices;
