import React from 'react'
import Intro from '../component/home_comp/Intro'
import Abtus from '../component/home_comp/Abtus'
import Services from '../component/home_comp/Services'
import Contactus from '../component/home_comp/Contact'
import Moreservices from '../component/home_comp/Moreservices'
import PourquoiNous from '../component/home_comp/PorquoiNous'

function Home() {
    return (
        <div>
            <Intro />
            <Abtus />
            <Services />
            <Moreservices />
            <PourquoiNous />
            <Contactus />
        </div>
    )
}

export default Home