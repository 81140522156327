import React, { useState } from "react";
import { useInView } from "react-intersection-observer";

function Contactus() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [text, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const apiUrl = "https://zlcargo-back.onrender.com";

  const { ref: headerRef, inView: headerInView } = useInView({ triggerOnce: true });
  const { ref: formRef, inView: formInView } = useInView({ triggerOnce: true });

  const Handlesub = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const Content = { name, email, number, text };

    try {
      const res = await fetch(`${apiUrl}/email`, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(Content),
      });

      if (!res.ok) throw new Error("Network error");

      setName("");
      setEmail("");
      setNumber("");
      setMessage("");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div id="contact" className="contact-container">
      <div className="contact-card">
        <div
          ref={headerRef}
          className={`contact-header ${headerInView ? "animate-header" : ""}`}
        >
          <h2>Contactez-Nous</h2>
        </div>
        <div
          ref={formRef}
          className={`contact-form ${formInView ? "animate-form" : ""}`}
        >
          <form onSubmit={Handlesub}>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Nom & Prénom"
              required
            />
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email@Email.com"
              required
            />
            <input
              type="tel"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              placeholder="Numéro de téléphone"
            />
            <textarea
              value={text}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Écrire votre message..."
              required
            ></textarea>
            <button type="submit" disabled={loading}>
              {loading ? "Envoi en cours..." : "Envoyer"}
            </button>
            {error && <p className="error-message">{error}</p>}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
